import React from 'react'
import styled, { css } from 'styled-components'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import siteConfig from '../../data/siteConfig'
import { withPrefix } from "gatsby"
import loadable from '@loadable/component'
import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'

const Layout = loadable(() => import('../components/layout'))

const Image = styled.img`
  max-height: 220px;
  max-width: 220px;
  object-fit: cover;
  object-position: center center;
  border-radius: 10px;
  box-shadow: 24px 47px 79px -21px rgba(0,0,0,0.51);
`
import contactStyles from '../components/styles/contact.css'

const divStyleLeft = {
    float: 'left',
    width: '48%',
    marginBottom: '1em',
    marginRright: '2 %'
}

const divStyleRight = {
    float: 'left',    
    marginBottom: '1em',
    width: '50%'
}

const Input = styled.input`
    border: 0;
    outline: 0;
    padding: 1em;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1em;
    box-shadow: 0px 1px 1px black;
    resize: none;
`

const Textarea = styled.textarea`
    border: 0;
    outline: 0;
    padding: 1em;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1em;
    box-shadow: 0px 1px 1px black;
    resize: none;
    height: 220px;
    margin-left: 20px;
`

const Contact = ({ className, location }) => {

    const title = "Contact"
    const { keywords } = siteConfig
    return (
        <Layout location={location}>
            <SEO
                title={title}
                keywords={keywords}
            />

            <Hero
                heroImg={withPrefix('/images/contact_cover.png')}
                title={title}
            />

            <Wrapper className={className}>
                <Container className="page-content" fluid>
                    <form className={contactStyles.cf}>
                        <div style={divStyleLeft} className={contactStyles.cf}>
                            <Input type="text" id="input-name" placeholder="Name"/>
                            <Input type="email" id="input-email" placeholder="Email address"/>
                            <Input type="text" id="input-subject" placeholder="Subject"/>
                        </div>
                        <div style={divStyleRight} className={contactStyles.cf}>
                         <Textarea name="message" type="text" id="input-message" placeholder="Message"></Textarea>
                      </div>
                      <Input type="submit" value="Submit" id="input-submit"/>
                  </form>                    
                </Container>
            </Wrapper>
        </Layout>
   )
}

export default styled(Contact)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }
`